<template>
    <footer>
        <div class="footer">
            <!-- <div class="footer_nav">
                <div>
                    <h4>Product</h4>
                    <a href="">Features</a>
                    <a href="">Testnet</a>
                    <a href="">Mainnet</a>
                </div>
                <div>
                    <h4>Product</h4>
                    <a href="">Features</a>
                    <a href="">Testnet</a>
                    <a href="">Mainnet</a>
                </div>
                <div>
                    <h4>Product</h4>
                    <a href="">Features</a>
                    <a href="">Testnet</a>
                    <a href="">Mainnet</a>
                </div>
            </div> -->
            <!-- <div class="footer_img">
                    <span>Audited by</span>
                    <img src="./img/cyberscope.png" alt="" srcset="">
            </div> -->

            <div class="line"></div>
            <div class="Share">
                <span>© 2024 PhecdaChain.</span>
                <div>
                    <a href="https://github.com/PcdDao"><img src="./img/Github.png" alt="" srcset=""></a>
                    <!-- <a href=""><img src="./img/telegram.png" alt="" srcset=""></a> -->
                    <!-- <a href="https://x.com/PhecdaChain"><img src="./img/twitter.png" alt="" srcset=""></a> -->
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style>
/*  */

footer {
    background: #191919;
    margin-top: 140px;
    padding: 60px 25px 30px;
}

.footer {
    width: 1200px;
    margin: auto;

}

.footer_nav {
    display: flex;
}

.footer_nav>div {
    display: flex;
    flex-direction: column;
    width: 350px;

}

.footer_nav h4 {
    font-family: Helvetica, Helvetica;
    font-weight: bold;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 36px;
    text-align: left;
    font-style: normal;
    margin-bottom: 10px;
}

.footer_nav a {
    font-family: Helvetica;
    font-size: 16px;
    color: rgb(227, 227, 227);
    line-height: 36px;
    text-align: left;
    font-style: normal;
}

.footer_img {
    display: flex;
    align-items: center;
    margin-top: 50px;
}


.footer_img span {
    font-family: Helvetica, Helvetica;
    font-weight: bold;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 36px;
    text-align: left;
    font-style: normal;
    margin-right: 20px;
}

.footer_img img {
    width: 180px;
}

/*  */
.line {
    width: 100%;
    height: 2px;
    background-color: #97979730;
    margin-top: 30px;
}

/*  */
.Share {
    display: flex;
    justify-content: space-between;
    padding: 30px 0 0;
}

.Share a {
    width: 28px;
    height: 28px;
    margin-left: 10px;
}

.Share a img {
    width: 100%;
    height: 100%;
}

.Share>div {
    display: flex;
    justify-content: space-between;
}

.Share span {
    font-family: Helvetica;
    font-size: 17px;
    color: #FFFFFF;
    line-height: 36px;
    text-align: left;
    font-style: normal;
}


@media screen and (max-width:1300px) {

    .footer {
        width: 90%;
    }
}

@media screen and (max-width:450px) {
    footer {
        background: #191919;
        margin-top: 80px;
        padding: 40px 25px 30px;
    }

    .footer_nav {
        flex-direction: column;
    }

    .footer_img img {
        width: 150px;
    }
}
</style>