<template>
  <div id="app">
    <div class="pages">
      <div class="top">
        <p class="scrollingText">The PCD public chain has been launched, with swap and cross-chain bridge features
          driving its development!
        </p>
      </div>
      <div class="page">
        <HeaderVue />
        <router-view></router-view>
      </div>
      <FooterVue />
    </div>

  </div>
</template>

<script>

import HeaderVue from '@/components/HeaderVue.vue'
import FooterVue from './components/FooterVue.vue';

export default {
  name: "App",
  components: { HeaderVue, FooterVue },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

:root {
  --t: 120s;
}

.pages {
  width: 100%;
  min-height: 100vh;
  background: url(./components/img/bj.png) no-repeat;
  background-color: black;
  background-size: 100vw 100vh;
  padding-bottom: 10px;
  overflow: hidden;
}

a {
  text-decoration: none;
  /* 移除下划线 */
  color: inherit;
  /* 使用父元素的颜色 */
  cursor: default;
  /* 如果你不想要手型指针，可以将其设为默认指针 */
}

a:visited {
  color: inherit;
  color: #fff;
  /* 移除访问过的链接的默认颜色变化 */
}

a:hover {
  text-decoration: none;
  /* 悬停时移除下划线 */
}

a:active {
  text-decoration: none;
  /* 激活时移除下划线 */
}

a:focus {
  outline: none;
  /* 移除焦点时的默认轮廓 */
}

ul {
  list-style: none;
}

.active {
  color: #ffcb30 !important;
}


.scrollingText {
  white-space: nowrap;
  animation: scrollText 30s linear infinite;
}

@keyframes scrollText {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.navTitle {
  display: flex;
  justify-content: space-between;
}

.navTitle h1 {
  font-family: TenSans, TenSans;
  font-weight: bold;
  font-size: 52px;
  color: #FFFFFF;
  line-height: 62px;
  text-align: left;
  font-style: normal;
}

.navTitle span {
  background: linear-gradient(270deg, #DD2DFF 0%, #5857FF 100%);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  font-family: Helvetica, Helvetica;
  font-weight: bold;
  font-size: 20px;
  color: #FFFFFF;
  text-align: left;
  font-style: normal;
  padding: 15px;
  box-sizing: border-box;
  height: 56px;
  cursor: pointer;
}

/*  */

.top {
  width: 100%;
  height: 50px;
  background: rgba(221, 243, 255, 0.3);
  opacity: 0.4;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
}

.top p {
  max-width: 1200px;
  text-align: center;
  line-height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  margin: auto;
  font-size: 16px;
  color: white;

}

/*  */

.page {
  width: 1200px;
  height: 100%;
  margin: auto;
}

@media screen and (max-width:1300px) {
  .page {
    width: 90%;
  }
}
</style>
