import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter)



const router = new VueRouter({
    routes: [
      {
        path: '/Index',
        component: () => import('@/components/Index.vue'),
      },
      {
        path: '/Labs',
        component: () => import('@/components/Labs.vue'),
      },
      {
        path: '/Bridge',
        component: () => import('@/components/Bridge.vue'),
      },
      // 重定向,项目
      {
        path: '*',
        redirect: '/Index'
      }
    ]
  });
  

export default router













// window.addEventListener('scroll', function() {
//     let rotateImg = document.querySelector('.rotateImg'); // 获取包含元素的容器
//     let element = rotateImg.querySelector('.rotateImgA'); // 获取需要操作的元素
//     let elementRect = element.getBoundingClientRect(); // 获取元素相对于视口的位置信息
//     let viewportHeight = window.innerHeight; // 获取视口的高度
//     let triggerPosition = elementRect.top - viewportHeight + 1000; // 触发动画的位置，相对于视口顶部的偏移量
//     if (window.scrollY >= triggerPosition) {
//         let scrollAmount = window.scrollY - triggerPosition; // 计算滚动距离
//         let scaleAmount = Math.min((scrollAmount/2) / viewportHeight, 1); // 根据滚动距离计算 scale 值，限制在 0 到 1 之间
//         let scaleValue = scaleAmount.toFixed(8); // 保留小数点后 8 位
//         element.style.opacity =scaleValue
//         element.style.transform = 'perspective(1200px) scale(' + scaleValue + ') rotateX(' + scaleValue + 'deg)'; // 设置元素的 transform 属性
//     } else {
//         element.style.transform = 'perspective(1200px) scale(0) rotateX(0)'; // 当元素还没有从底部出现时，将 scale 值设置为 0
//     }
// });


//    window.addEventListener('load',function(){
//     const addImgAll = document.querySelectorAll('.addImg')
//       addImgAll.forEach(item=>{
//           item.addEventListener('click',function(){
//               if (item.parentNode.nextElementSibling.style.display != 'block') {
//                   item.parentNode.nextElementSibling.style.display = 'block'
//                   item.style.transform = 'rotateZ(45deg)'
//               }else{
//                   item.parentNode.nextElementSibling.style.display = 'none'
//                   item.style.transform = 'rotateZ(0deg)'
//               }
//           })
//       })
//    })
