<template>
    <div>
        <div class="nav">
            <div class="logo"><img src="./img/logo.png" alt="" srcset=""></div>
            <div class="navList">
                <a href="#/Index" class="showNav">Index</a>
                <a href="#/Labs" class="showNav">Labs</a>
                <a href="https://docs.pcddao.com/" class="showNav">Docs</a>
                <a href="#/Bridge" class="showNav">Bridge</a>
                <a href="https://swap.pcddao.com/" class="showNav">Swap</a>
                <a class="showNav">Mainnet
                    <div>
                        <ul>
                            <!-- <li><a href="https://docs.pcddao.com/pcddaos-organization-docs/for-developers/testnet-details">For Ddevs</a></li> -->
                            <li><a
                                    href="https://docs.pcddao.com/pcddaos-organization-docs/for-developers/mainnet-details">For
                                    Miners</a></li>
                            <li><a href="https://pcdscan.pcddao.com/">Pcdscan</a></li>
                        </ul>
                    </div>
                </a>
                <!-- <a class="showNav">Testnet
                    <div>
                        <ul>
                            <li><a href="https://staking.test.pcddao.com/">Staking</a></li>
                            <li><a href="http://pcdscan.test.pcddao.com/">Pcdscan</a></li>
                            <li><a href="https://faucet.test.pcddao.com/">Faucet</a></li>
                        </ul>
                    </div>
                </a> -->
                <a href="https://tokendiy.pcddao.com/" class="showNav">TokenDIY</a>
                <!-- <a href="" class="active showNav">$ 2.201</a> -->
                <!-- <a href="" class="showNav"><img src="./img/en.png" alt="" srcset=""><span>English</span></a> -->
                <a href="#" @click="navFlag = true"><svg t="1714794810902" class="icon" viewBox="0 0 1024 1024"
                        version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12817" width="200" height="200">
                        <path
                            d="M127.9 168.9h768v46h-768zM127.2 809.1h768v46h-768zM408.1 495.5h488.7v46H408.1zM317.6 346.3L130.1 522.6l187.5 176.3V346.3z m-46 246.2l-74.3-69.9 74.3-69.9v139.8z"
                            p-id="12818" fill="#ffffff"></path>
                    </svg></a>
            </div>
        </div>

        <div class="navB" v-show="navFlag">
            <div class="navTwo">
                <div class="logo"><img src="./img/logo.png" alt="" srcset=""></div>
                <div class="navList">
                    <a href="#" @click="navFlag = false"><svg t="1714798443381" class="icon" viewBox="0 0 1024 1024"
                            version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2691" width="200" height="200">
                            <path
                                d="M760.96 195.2L512 444.16 263.04 195.2a48 48 0 0 0-67.84 67.84L444.16 512l-248.96 248.96A48 48 0 0 0 263.04 832L512 579.84 760.96 832A48 48 0 0 0 832 760.96L579.84 512 832 263.04a48 48 0 0 0-67.84-67.84z"
                                p-id="2692" fill="#ffffff"></path>
                        </svg></a>
                </div>

            </div>
            <div class="navMax">
                <a href="#/Index" @click="navFlag = false">Index</a>
                <a href="#/Labs" @click="navFlag = false">Labs</a>
                <a href="https://docs.pcddao.com/" @click="navFlag = false">Docs</a>
                <a href="#/Bridge" @click="navFlag = false">Bridge</a>
                <a href="https://swap.pcddao.com/" @click="navFlag = false">Swap</a>
                <!-- navMax_title 导航标题 -->
                <div class="navMax_title">Mainnet</div>
                <!-- <a href="https://docs.pcddao.com/pcddaos-organization-docs/for-developers/testnet-details" @click="navFlag = false">For Ddevs</a> -->
                <a href="https://docs.pcddao.com/pcddaos-organization-docs/for-developers/mainnet-details"
                    @click="navFlag = false">For Miners</a>
                <a href="https://pcdscan.pcddao.com/" @click="navFlag = false">Pcdscan</a>
                <div class="navMax_title">TokenDIY</div>
                <a href="https://tokendiy.pcddao.com/" @click="navFlag = false">TokenDIY</a>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'headerVue',
    data() {
        return {
            navFlag: false
        }
    },
}
</script>

<style>
.navList a svg {
    width: 30px;
    height: 30px;
}

.navList>a>div {
    width: 100%;
    padding-top: 30px;
    display: none;


}

.navList>a>div>ul {
    width: 100%;
    background-color: #191919;
    border-radius: 20px;
    padding: 10px 0;
}

/* 
.navMax>a>ul{
    width: 100%;
    padding: 10px 0;
    display: none;

}

.navMax>a:hover ul{
    display: block;
}
.navMax>a>ul>li{
    display: block !important;
    width: 100% !important;
    height: 30px;
    line-height: 30px;
    text-align: center;
} */

.navList>a:hover div {
    display: block;
}

.navList>a>div>ul>li {
    display: block !important;
    width: 100% !important;
    height: 30px;
    line-height: 30px;
    text-align: center;
}

.navList>a>div>ul>li a {
    color: white;
    font-size: 17px;
}


.navB {
    position: absolute;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100vw;
    height: 1000px;
    padding-top: 30px;
    background: black;
    box-sizing: border-box;
}

.navTwo {
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: auto;
}



.navMax {
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
    box-sizing: border-box;

}

.navMax a {
    /* text-align: center; */
    color: #FFF;
    font-size: 20px;
    margin: 10px 0;
    border-bottom: 1px solid rgb(34, 34, 34);
    padding-bottom: 20px;
}

.navMax_title {
    color: #ffffff66;
    margin: 30px 0 15px;
}

/*  */
.nav {
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding: 15px 0;
}

.nav .logo {
    width: 141px;
    height: 35px;
}

.nav .logo img {
    width: 100%;
    height: 100%;
}

.nav .navList {
    display: flex;
    align-items: center;
}

.nav .navList>a {
    height: 24px;
    font-family: Helvetica, Helvetica;
    font-weight: bold;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 24px;
    font-style: normal;
    margin-left: 50px;
    position: relative;

}

.nav .navList a svg {
    width: 30px;
    height: 30px;
}

.nav .navList a img {
    width: 18px;
    height: 18px;
    vertical-align: middle;
    margin-right: 5px;
}


@media screen and (max-width:1000px) {
    .navTitle h1 {
        font-size: 40px;
    }

    .showNav {
        display: none;
    }

    .navB .logo img {
        width: 110px !important;
        height: 25px !important;
    }
}

@media screen and (max-width:450px) {


    .navTitle {
        align-items: center;
    }

    .navTitle h1 {
        font-size: 18px;
    }

    .navTitle span {
        font-size: 18px;
        padding: 0 15px;
        height: 40px;
        line-height: 40px;

    }
}
</style>